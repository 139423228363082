import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Initializer } from './Initializer'
import * as SyllabarySymbol from '../syllabarySymbol'

/**
 *
 */
export const NameSortedInitializer = ({ companies = null, children }) => {
  const entries = companies ?? useStaticQuery(query).nodes

  return (
    <Initializer companies={entries}>
      <SyllabarySymbol.Initializer>{children}</SyllabarySymbol.Initializer>
    </Initializer>
  )
}

/**
 *
 */
export const query = graphql`
  {
    companies: allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: "companies" } } } }
      sort: { fields: acf___nameHiragana }
    ) {
      nodes {
        id
        title
        slug
        content
        categories {
          slug
        }
        acf {
          nameHiragana
        }
      }
    }
  }
`
