import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Provider } from './Provider'

/**
 *
 */
export const Initializer = ({ companies = null, children }) => {
  const entries = companies ?? useStaticQuery(query).nodes
  return <Provider value={entries}>{children}</Provider>
}

/**
 *
 */
export const query = graphql`
  {
    companies: allWordpressPost(filter: { slug: { eq: "companies" } }) {
      nodes {
        id
        title
        content
      }
    }
  }
`
