import React from 'react'
import { withSymbol } from '../withSymbol'

/**
 *
 */
export const syllabarySymbol = {
  A: 'あ行',
  KA: 'か行',
  SA: 'さ行',
  TA: 'た行',
  NA: 'な行',
  HA: 'は行',
  MA: 'ま行',
  YA: 'や行',
  RA: 'ら行',
  WA: 'わ行',
}

/**
 *
 */
export const syllabaries = [
  { symbol: syllabarySymbol.A, firstCharacter: 'あ', lastCharacter: 'お' },
  { symbol: syllabarySymbol.KA, firstCharacter: 'か', lastCharacter: 'こ' },
  { symbol: syllabarySymbol.SA, firstCharacter: 'さ', lastCharacter: 'そ' },
  { symbol: syllabarySymbol.TA, firstCharacter: 'た', lastCharacter: 'と' },
  { symbol: syllabarySymbol.NA, firstCharacter: 'な', lastCharacter: 'の' },
  { symbol: syllabarySymbol.HA, firstCharacter: 'は', lastCharacter: 'ほ' },
  { symbol: syllabarySymbol.MA, firstCharacter: 'ま', lastCharacter: 'も' },
  { symbol: syllabarySymbol.YA, firstCharacter: 'や', lastCharacter: 'よ' },
  { symbol: syllabarySymbol.RA, firstCharacter: 'ら', lastCharacter: 'ろ' },
  { symbol: syllabarySymbol.WA, firstCharacter: 'わ', lastCharacter: 'ん' },
]

export const GroupSymbol = () => [{ A: [company1, company2] }]

/**
 *
 */
const DefaultTextRenderer = ({ value }) => <p>{value}</p>

/**
 *
 */
export const Symbol = withSymbol(
  ({ symbol, render: Renderer = DefaultTextRenderer, ...props }) => {
    return <Renderer value={symbol} {...props} />
  }
)
