import React from 'react'
import * as styles from './styles.module.scss'
import * as Button from '../../Button'
import * as Links from '../../Links'
/**
 *
 */
const Entry = () => (
  <div className={styles.Container_Link}>
    <Button.BackButton className={styles.Container_Link_BackButton} />
    <Links.ToTopPage>
      <Button.DefaultButton className={styles.Container_Link_TopButton}>
        &lt; TOPへ戻る
      </Button.DefaultButton>
    </Links.ToTopPage>
  </div>
)

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
export const Content = () => (
  <Container>
    <Entry />
  </Container>
)
