import React from 'react'
import { Content as Entries } from './Entries'
import * as styles from './styles.module.scss'

/**
 *
 */
const Links = () => (
  <div className={styles.Entries}>
    <Entries />
  </div>
)

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Links />
  </Container>
)
