import React from 'react'

export const DefaultButton = ({
  children = '一覧を表示',
  className,
  ...props
}) => {
  return (
    <button type="button" className={className}>
      {children}
    </button>
  )
}

/**
 *
 */
export const BackButton = ({ children = '戻る', className, ...props }) => {
  return (
    <button type="button" className={className} onClick={() => history.back()}>
      戻る
    </button>
  )
}
