import React from 'react'
import { Context } from './Context'

/**
 *
 */
export const withSymbol = (Component) => (props) => {
  const { symbol } = React.useContext(Context)
  return <Component symbol={symbol} {...props} />
}
