import React from 'react'
import { Content as SymbolContent } from './Symbol'
import * as Symbols from 'modules/company/syllabarySymbol'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 * FIXME: InitializerをGroupByの形に変更し、GroupごとにCompaniesの件数等を算出できるようにMODULEを変更するべき
 */
const Entries = () => (
  <div className={styles.EntriesContent}>
    <div className={styles.EntriesContent_LeftStyles}>
      <Symbols.Each start={0} end={5}>
        <div className={styles.EntriesContent_SymbolContent}>
          <SymbolContent />
        </div>
      </Symbols.Each>
    </div>
    <div className={styles.EntriesContent_RightStyles}>
      <Symbols.Each start={5} end={10}>
        <div className={styles.EntriesContent_SymbolContent}>
          <SymbolContent />
        </div>
      </Symbols.Each>
    </div>
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Entries />
  </Container>
)
