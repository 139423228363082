import React from 'react'
import * as syllabarySymbolReference from '../syllabarySymbol/Reference'

/**
 *
 */
export const TYPE = {
  SYMBOL: 0,
}

/**
 *
 */
const filterBySymbol = (company) => {
  const { symbol } = React.useContext(syllabarySymbolReference.Context)
  const companyName = company.acf.nameHiragana
  if (companyName == null) {
    return false
  }

  const companyFirstChar = companyName.charAt(0)
  const syllabary = syllabarySymbolReference.Properties.syllabaries.find(
    (element) => {
      return element.symbol === symbol
    }
  )
  if (syllabary == null) {
    return false
  }
  if (
    !(
      syllabary.firstCharacter <= companyFirstChar &&
      companyFirstChar <= syllabary.lastCharacter
    )
  ) {
    return false
  }

  return true
}

/**
 *
 */
export const filterByType = (type) => {
  let filterFunc = null
  switch (type) {
    case TYPE.SYMBOL:
      filterFunc = filterBySymbol
      break
    default:
      break
  }

  return filterFunc
}
