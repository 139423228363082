import React from 'react'
import * as Company from 'modules/company/components/Reference/Properties'
import * as Companies from 'modules/company/components'
import { ToEachCompanyLink } from 'components/shared/Links'
import * as styles from './styles.module.scss'
/**
 *
 */
const CompanyNames = () => (
  <div className={styles.CompanyName}>
    <Companies.Each filterTypeList={[Companies.TYPE.SYMBOL]}>
      <ToEachCompanyLink>
        <Company.Title />
      </ToEachCompanyLink>
    </Companies.Each>
  </div>
)

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
export const Content = () => (
  <Container>
    <CompanyNames />
  </Container>
)
