import React from 'react'
import { Content as CompaniesContent } from './Companies'
import { Content as SyllabarySymbolContent } from './Header'

const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const Header = () => (
  <div>
    <SyllabarySymbolContent />
  </div>
)
/**
 *
 */
const Body = () => (
  <div>
    <CompaniesContent />
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Header />
    <Body />
  </Container>
)
