//FIXME
import React from 'react'
import { Content as EntriesContent } from './CompaniesLists'
import { Content as Links } from 'components/shared/Common_button'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const CompaniesLists = () => (
  <div>
    <EntriesContent />
  </div>
)

/**
 *
 */
const Link = () => (
  <div>
    <Links />
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <CompaniesLists />
    <Link />
  </Container>
)
