import React from 'react'
import { Provider } from './Provider'
import * as Syllabary from './Reference'

/**
 *
 */
export const Initializer = ({ children }) => {
  const symbols = Object.values(Syllabary.Properties.syllabarySymbol)
  return <Provider value={{ symbols }}>{children}</Provider>
}
