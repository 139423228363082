import { symbol } from 'prop-types'
import React from 'react'
import { Context } from './Context'
import { Provider as ReferenceProvider } from './Reference'

const ChildrenRenderer = ({ children }) => <>{children}</>
/**
 *
 */
export const Each = ({
  render: Renderer = ChildrenRenderer,
  start,
  end,
  ...props
}) => {
  const { symbols } = React.useContext(Context)
  // start=あ行、end=は行を指定したときに、そのデータを抽出する関数を作成する
  // const entries = symbols.slice(start, end)
  const CreateData = (start, end, data) => {
    if (start || end) {
      return data.slice(start, end)
    } else {
      return data
    }
  }
  const entries = CreateData(start, end, symbols)
  return entries.map((entry) => (
    <ReferenceProvider key={entry} symbol={entry}>
      <Renderer symbol={entry} {...props} />
    </ReferenceProvider>
  ))
  //あ行、か行、さ行
}
