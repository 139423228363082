import React from 'react'
import { Content } from 'partials/CompaniesIndexPage/Content'
import { graphql } from 'gatsby'
import { NameSortedInitializer } from 'modules/company/components'

/**
 *
 */
const Page = ({ data }) => {
  return (
    <NameSortedInitializer companies={{ companies: data.companies.nodes }}>
      <Content />
    </NameSortedInitializer>
  )
}

/**
 *
 */
export const query = graphql`
  query companyIndexQuery($postStatus: String!) {
    companies: allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { eq: "companies" } } }
        status: { glob: $postStatus }
      }
      sort: { fields: acf___nameHiragana }
    ) {
      nodes {
        id
        title
        slug
        content
        categories {
          slug
        }
        acf {
          nameHiragana
        }
      }
    }
  }
`

/**
 *
 */
export default Page
