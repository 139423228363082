import React from 'react'
import * as Properties from 'modules/company/syllabarySymbol/Reference/Properties'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const SyllabarySymbol = () => (
  <div className={styles.SyllabarySymbol}>
    <Properties.Symbol />
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <SyllabarySymbol />
  </Container>
)
