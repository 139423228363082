import React from 'react'
import { Provider as ReferenceProvider } from './Reference'
import { Context } from './Context'
import * as Filter from './Filter'

/**
 *
 */
export const Each = ({ children, filterTypeList = [] }) => {
  const { companies } = React.useContext(Context)
  const filterFuncList = filterTypeList.map((filterType) =>
    Filter.filterByType(filterType)
  )

  companies.filter((entry) =>
    filterFuncList
      .map((filterFunc) => filterFunc(entry))
      .every((filterResult) => filterResult === true)
  )

  return companies
    .filter((entry) =>
      filterFuncList
        .map((filterFunc) => filterFunc(entry))
        .every((filterResult) => filterResult === true)
    )
    .map((entry) => {
      const cSlug = entry.categories.find((tmp) => {
        return tmp.slug !== 'companies'
      })
      return (
        <ReferenceProvider
          key={entry.id}
          company={{ entry, cSlug: cSlug.slug }}
        >
          {children}
        </ReferenceProvider>
      )
    })
}
